// extracted by mini-css-extract-plugin
export var fullWidthFooter = "footer-module--full-width-footer---Xb1m";
export var footerContainer = "footer-module--footer-container--oUD3x";
export var socialIconGroup = "footer-module--social-icon-group--qgztt";
export var icons = "footer-module--icons--7tQzI";
export var footerTitle = "footer-module--footer-title--dCUhZ";
export var footerLinks = "footer-module--footer-links--yuOVT";
export var iconGroup = "footer-module--icon-group--KkMyP";
export var copyrightText = "footer-module--copyright-text--Izm7w";
export var storeHours = "footer-module--store-hours--mI--i";
export var linksGroup = "footer-module--links-group--yB3x5";
export var item = "footer-module--item--FoMuc";
export var footerLogo = "footer-module--footer-logo---W4g8";
export var logoGroup = "footer-module--logoGroup--L9wAJ";
export var itemGroupContainer = "footer-module--itemGroupContainer--t-qiT";
export var itemGroup = "footer-module--itemGroup--pMRQS";