/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { Helmet } from "react-helmet";
import { linkResolver } from "../../linkResolver";

import "./layout.css";
import "../../scss/main.scss";

// import Banner from '../banner/banner';
import Navigation from "../navigation/navigation";
import Footer from "../footer/footer";
// import Banner from "../sitewideBanner/banner";

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
			# prismicSiteWideAlertBanner {
			# 	data {
			# 		site_wide_alert_banner_toggle
			# 		site_wide_alert_banner_headline {
			# 			richText
			# 		}
			# 	}
			# }
			prismicNavigation {
				data {
					navigation_logo {
						url
						gatsbyImageData(layout: CONSTRAINED)
					}
					navigation_group {
						navigation_group_link_text
						navigation_group_link {
							id
							url
						}
					}
				}
				_previewable
			}
			prismicFooter {
				data {
					footer_logo {
						gatsbyImageData(layout: FULL_WIDTH)
					}
					# footer_copyright_privacy_policy_text {
					# 	richText
					# }
					footer_address_text {
						richText
					}
					footer_store_hours {
						richText
					}
					footer_quick_links_group {
						footer_quick_links_link_text
						footer_quick_links_link {
							url
							uid
							link_type
							target
						}
					}
				}
				_previewable
			}
		}
	`);

	return (
		<>
			<Helmet>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>
				<link rel="manifest" href="/site.webmanifest" />
				<link
					rel="mask-icon"
					href="/safari-pinned-tab.svg"
					color="#5bbad5"
				/>
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />

				<link
					rel="stylesheet"
					href="https://use.typekit.net/kzw2dhw.css"
				/>
				<link
					href="https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Coda+Caption:wght@800&family=Holtwood+One+SC&family=Open+Sans:wght@600&display=swap"
					rel="stylesheet"
				/>
				<link
					href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"
					rel="stylesheet"
				/>
			</Helmet>
			<PrismicProvider // used to set up global configuration for <PrismicLink> elements
				// eslint-disable-next-line react/prop-types
				internalLinkComponent={(props) => (
					<Link to={props?.href} {...props} />
				)}
				linkResolver={linkResolver}
			>
				{/* <Banner data={data.prismicSiteWideAlertBanner} /> */}
				<Navigation data={data.prismicNavigation.data} />
				<div>
					<main>{children}</main>
				</div>
				<Footer data={data.prismicFooter.data} />
			</PrismicProvider>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
