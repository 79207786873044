import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { PrismicLink, PrismicText, PrismicRichText } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import * as styles from "./alertBanner.module.scss";
import Icon from "../icon/icon";

const AlertBanner = ({ slice }) => {
	const doc = slice;

	console.log('ALERT BANNER => ', doc);

	if (!doc) return null;

	return (
		<div className={`${styles.banner} d-flex container`}>
			<div className="row">
				<div className="d-inline-flex">
					<Icon
						classList={styles.icon}
						width="30px"
						icon={doc?.primary?.alert_banner_icon}
						alt="icon-img"
						tabIndex={-1}
					/>
					<div className={styles.bannerContent}>
						<PrismicRichText
							components={{
								heading4: ({ children }) => (
									<h4 className={`m-b-0 ${styles.text}`}>
										{children}
									</h4>
								),
								paragraph: ({ children }) => (
									<p className={`m-b-0 ${styles.text}`}>
										{children}
									</p>
								),
							}}
							field={doc?.primary?.alert_banner_text.richText}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withPrismicPreview(AlertBanner);

AlertBanner.propTypes = {
	data: PropTypes.object.isRequired,
};
