import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { PrismicLink, PrismicText, PrismicRichText } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import * as styles from "./featuredProducts.module.scss";
import FilterArrayByType from "../../utility";

const FeaturedProducts = ({ slice }) => {
	if (!slice) return null;

	// console.log(doc);

	// const docFeaturedProduct = FilterArrayByType(
	// 	doc.body,
	// 	"featured_product_section"
	// );

	// console.log("docFeaturedProduct = > ", docFeaturedProduct);

	return (
		<>
			<div
				className={`container ${styles.featuredProductContainer}`}
				key={slice?.primary?.featured_product_section_title}
			>
				<div className="row">
					<div className="col-xs-12">
						<div className={styles.sectionTitle}>
							<span>
								{slice?.primary?.featured_product_section_title}
							</span>
						</div>
					</div>
				</div>
				<div className="row">
					{slice?.items.map((item) => (
						<div className={`col-sm-4 ${styles.productBox}`}>
							{item?.featured_product_box_on_sale_toggle && (
								<div className={styles.onSale}>On Sale</div>
							)}
							<GatsbyImage
								image={getImage(
									item?.featured_product_box_image
								)}
								className={styles.image}
								alt=""
							/>
							<div className={styles.title}>
								{item?.featured_product_box_title}
							</div>
							<div className={styles.description}>
								{item?.featured_product_box_description}
							</div>
							<div className={styles.price}>
								{item?.featured_product_box_price}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default withPrismicPreview(FeaturedProducts);

FeaturedProducts.propTypes = {
	slice: PropTypes.object.isRequired,
};
