import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { PrismicLink, PrismicText, PrismicRichText } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import * as styles from "./callToAction.module.scss";

const CallToAction = ({ slice }) => {
	if (!slice) return null;

	// console.log(slice);
	// const heroImage = getImage(slice.homepage_hero_background_image);

	return (
		<>
			<div
				style={{
					backgroundImage: `url(${slice.primary.call_to_action_background_image.url})`,
				}}
				className={`${styles.callToActionBgImg}`}
			>
				<div className={`container ${styles.contentGroup}`}>
					<div className={`${styles.title}`}>
						<PrismicRichText
							components={{
								paragraph: ({ children }) => (
									<p className={`m-b-0 ${styles.text}`}>
										{children}
									</p>
								),
							}}
							field={slice.primary.call_to_action_title.richText}
						/>
					</div>

					<div className={styles.description}>
						{slice.primary.call_to_action_description}
					</div>
					<div className={styles.buttonGroup}>
						<PrismicLink
							className={styles.button}
							field={slice.primary.call_to_action_button_url}
							style={{
								backgroundColor:
									slice.primary.call_to_action_button_color,
							}}
						>
							{slice.primary.call_to_action_button_text}
						</PrismicLink>
					</div>
				</div>
			</div>
		</>
	);
};

export default withPrismicPreview(CallToAction);

CallToAction.propTypes = {
	slice: PropTypes.object.isRequired,
};
