/* eslint-disable react/prop-types */
import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { PrismicLink, PrismicText, PrismicRichText } from "@prismicio/react";

import * as styles from "./footer.module.scss";
// import AngledStripe from "../angledStripe/angledStripe";

const Footer = ({ data }) => {
	const doc = data;
	if (!doc) return null;

	const footerLogo = getImage(doc?.footer_logo);

	const iconGroup = (
		<>
			<div className="d-flex flex-column">
				<div className={styles.addressBox}>
					<PrismicRichText
						components={{
							paragraph: (children) => {
								<p>{children.node.text}</p>;
							},
							heading2: (children) => {
								<h2>{children.node.text}</h2>;
							},
							heading4: ({ children }) => (
								<h4 className="headline-font">{children}</h4>
							),
						}}
						field={doc?.footer_address_text.richText}
					/>
				</div>
				<div className="d-flex">
					<a
						href="https://www.facebook.com/GlobalLiquorKS"
						target="_blank"
						rel="noreferrer"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="40"
							height="40"
							viewBox="0 0 40 40"
							className={styles.icons}
						>
							<defs>
								<clipPath id="clip-path">
									<rect
										id="Rectangle_66"
										data-name="Rectangle 66"
										width="40"
										height="40"
										fill="none"
									/>
								</clipPath>
							</defs>
							<g
								id="Group_22"
								data-name="Group 22"
								clipPath="url(#clip-path)"
							>
								<path
									id="Path_79"
									data-name="Path 79"
									d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0m5.63,12.086-2.23,0c-1.749,0-2.088.83-2.088,2.05v2.689h4.171l-.542,4.213H21.31V31.847H16.962V21.039H13.323V16.826h3.638V13.721c0-3.606,2.2-5.569,5.418-5.569a29.553,29.553,0,0,1,3.25.167Z"
									transform="translate(0 0)"
									fill="var(--bs-light)"
								/>
							</g>
						</svg>
					</a>
				</div>
			</div>
		</>
	);

	return (
		<footer className={`${styles.fullWidthFooter}`}>
			<div className={`${styles.footerContainer} container d-flex`}>
				<div className="row w-100">
					{/* LEFT column */}
					<div className="d-flex flex-column justify-content-between align-items-center align-items-sm-stretch col-lg-4 col-md-4 col-12">
						<div className={`${styles.logoGroup}`}>
							<a
								href="https://www.facebook.com/GlobalLiquorKS"
								target="_blank"
							>
								<GatsbyImage
									className={styles.footerLogo}
									image={footerLogo}
									alt="Global Wine & Spirits Logo"
								/>
							</a>
						</div>
						<div
							className={`${styles.iconGroup} d-none d-md-flex flex-column justify-content-between`}
						>
							{iconGroup}
						</div>
					</div>
					<div className={`${styles.linksGroup} col-lg-8 col-md-12`}>
						<div
							className={`d-flex flex-column ${styles.itemGroupContainer}`}
						>
							<div
								className={`d-flex flex-wrap row ${styles.itemGroup}`}
							>
								<div
									className={`${styles.item} order-1 col-lg-7 col-md-7`}
								>
									<div className={styles.storeHours}>
										<PrismicRichText
											components={{
												paragraph: (children) => {
													<p>{children.node.text}</p>;
												},
												heading2: (children) => {
													<h2>
														{children.node.text}
													</h2>;
												},
												heading4: ({ children }) => (
													<h4 className="headline-font">
														{children}
													</h4>
												),
											}}
											field={
												doc?.footer_store_hours.richText
											}
										/>
									</div>
								</div>

								<div
									className={`${styles.item} order-3 order-lg-2 col-lg-5 col-md-5`}
								>
									{/* <div className={`${styles.footerTitle}`}>
										QUICK LINKS
									</div>
									{doc?.footer_quick_links_group.map(
										(item) => (
											<div key={Math.random(0, 99999)}>
												<Link
													className={`${styles.footerLinks}`}
													to={
														item
															?.footer_quick_links_link
															.url
													}
												>
													{
														item?.footer_quick_links_link_text
													}
												</Link>
											</div>
										)
									)} */}
									<div
										className="d-flex flex-grow-1"
										style={{
											width: "100%",
											height: "300px",
										}}
									>
										<iframe
											title="map"
											scrolling="no"
											marginHeight="0"
											marginWidth="0"
											src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=3550%20N%20Woodlawn%20Blvd,%20Ste%20200%20Wichita,%20KS%2067220+(Global%20Wine%20&amp;%20Spirits)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
											width="100%"
											height="100%"
											frameBorder="0"
										>
											<a href="https://www.gps.ie/wearable-gps/">
												wearable trackers
											</a>
										</iframe>
									</div>
								</div>
								<div
									className={`${styles.iconGroup} order-5 d-flex d-md-none flex-column justify-content-between`}
								>
									{iconGroup}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <AngledStripe /> */}
		</footer>
	);
};

// Must include this "withPrismicPreview" in order for it to work
// Must also include "_previewable" in the query
export default withPrismicPreview(Footer);

Footer.propTypes = {
	data: PropTypes.object.isRequired,
};
