import React from "react";
import { Link, graphql } from "gatsby";
import { PrismicRichText, PrismicLink } from "@prismicio/react";
import PropTypes from "prop-types";

import * as styles from "./generalContentWithHeadline.module.scss";

const GeneralContent = ({ slice }) => {
	if (!slice) return null;

	return (
		<div className="container">
			<div className={styles.generalContentGroup}>
				{slice?.primary.general_content_headline && (
					<div className="row">
						<div className="col">
							<h2>{slice?.primary.general_content_headline}</h2>
						</div>
					</div>
				)}

				<div className="row">
					{slice?.items?.map((item) => (
						<div
							className="col-md col-sm-12"
							key={Math.random(0, 99999)}
						>
							<PrismicRichText
								components={{
									image: (children) => {
										/* 
                    Adding a width parameter below works because imgx is dope
                    Set to double the max rendered size for retina (1642)
                    Will be compressed by Prismic + imgix due to "auto=compress,format"
                    Took a 1.85M image to 116KB compressed to 46KB with width/height
                    Took a 6.08M image to 292KB compressed to 62KB with width/height
                      ------!  LITERALLY 1% OF INITIAL FILE SIZE  !-------

                    We have to do rejex here because the default links provided
                    through the RichText field come straight from AWS, and not
                    from the image API that uses imgix.

                    An example of the two different endpoints:
                      https://prismic-io.s3.amazonaws.com/flykc/34815b47-b087-46cf-9f19-5d2d25d98011_R1-07687-0004_better.jpg
                      https://images.prismic.io/flykc/34815b47-b087-46cf-9f19-5d2d25d98011_R1-07687-0004_better.jpg?auto=compress,format
                  */
										const path = children.node.url;
										const pattern =
											/^.*prismic-io\.s3\.amazonaws\.com(.*)/;
										let url = path;
										if (path.match(pattern)?.length > 0) {
											url = `https://images.prismic.io${
												path.match(pattern)[1]
											}?&auto=compress,format&fit=max&q=50&w=1642&h=1642`;
										}
										return (
											<img
												alt={children.node.alt || ""}
												className={`${styles.image}`}
												src={url}
											/>
										);
									},
									heading2: ({ children }) => (
										<h2 className="">{children}</h2>
									),
									heading4: ({ children }) => (
										<h4 className="">{children}</h4>
									),
								}}
								field={item?.general_content_content.richText}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default GeneralContent;

GeneralContent.propTypes = {
	slice: PropTypes.object.isRequired,
};
