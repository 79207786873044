import React, { useState, useEffect, useRef } from "react";
import { Link, navigate } from "gatsby";
import * as styles from "./navigation.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import JSON from "./navigation.json";
// import logo from "./logo.svg";
// import Icon from '../icon/icon';

const Navigation = ({ data }) => {
	const [active, setActive] = useState(false);
	const navElement = useRef();
	const isShrunk = useRef(false);

	const SHRINK_HEIGHT = 200;
	const SHRINK_BUFFER = 50; // Dial this in to prevent jitter (related to the change in overall nav height)

	const [mobileNavOpen, setMobileNavOpen] = useState(false);

	const [searchTerm, setSearchTerm] = useState("");

	const hideActive = () => {
		setActive(null);
	};

	const toggleMobileNav = () => {
		setMobileNavOpen(!mobileNavOpen);
	};
	const handleChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const handleSubmit = () => {
		navigate(`/search?query=${searchTerm}`);
	};

	const changeActive = (e) => {
		const key = e.target.getAttribute("data-key");

		// Toggle off if the open one is clicked again
		if (active === key) {
			setActive(null);
		} else {
			setActive(key);
		}
	};

	const navLogo = getImage(data?.navigation_logo);

	return (
		<nav
			id="navigation-container"
			className={`${styles.navigationContainer}`}
			onMouseLeave={hideActive}
			role="navigation"
			aria-label="Main"
			ref={navElement}
		>
			{/* Main Navigation */}
			<div className="d-flex justify-content-center align-items-center flex-column">
				<div className="row">
					<div className="d-flex">
						<Link to="/">
							<img
								className={styles.logo}
								alt="Global Wine & Spirits Logo"
								src={data?.navigation_logo.url}
							/>
						</Link>
					</div>
				</div>
				<div className={`${styles.linkRow}`}>
					<div className="row">
						<div className="container d-flex">
							<ul
								className={`${styles.navigation} headline-font d-none d-xl-flex`}
								role="menu"
							>
								{/* {data.navigation_group.map((item) => (
									<React.Fragment
										key={item.navigation_group_link_text}
									>
										<Link
											to={item.navigation_group_link.url}
											key={
												item.navigation_group_link_text
											}
											role="menuitem"
											aria-label={
												item.navigation_group_link_text
											}
											data-key={
												item.navigation_group_link_text
											}
											onFocus={changeActive}
											tabIndex={0}
											className={styles.menuLink}
										>
											<label
												onMouseEnter={changeActive}
												data-key={
													item.navigation_group_link_text
												}
												className={
													active ===
													item.navigation_group_link_text
														? `${styles.links} is-active`
														: `${styles.links}`
												}
												title={
													item.navigation_group_link_text
												}
											>
												{
													item.navigation_group_link_text
												}
											</label>
										</Link>
									</React.Fragment>
								))} */}
							</ul>
						</div>
					</div>
				</div>
				{/* END DESKTOP NAV */}
				{/* MOBILE NAV */}
				<div className="d-flex d-none justify-content-end">
					<div
						role="menuitem"
						onClick={toggleMobileNav}
						onKeyPress={toggleMobileNav}
						tabIndex={0}
						className={styles.menuLink}
					>
						{!mobileNavOpen && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
								/>
							</svg>
						)}
						{mobileNavOpen && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
							</svg>
						)}
					</div>
					<div
						className={`${styles.mobileDropdownContainer} ${
							mobileNavOpen ? styles.open : ""
						}`}
					>
						{/* {data.navigation_group.map((data) => (
							<div
								key={data.name}
								className={`${styles.mobileDropdown} `}
							>
								<div className="d-flex justify-content-between align-items-center">
									<Link
										to={data.path}
										className={styles.mobileLink}
									>
										{data.name}
									</Link>
									{data.subnav && (
										<div
											key={data.name}
											className={`${
												styles.subPlusElementContainer
											} ${
												active === data.name
													? styles.open
													: ""
											}`}
										>
											<div
												aria-label={data.name}
												role="menuitem"
												data-key={data.name}
												tabIndex={0}
												onClick={changeActive}
												onKeyPress={changeActive}
												style={{
													position: "absolute",
													inset: 0,
													zIndex: 1,
												}}
											/>
											<div
												className={
													styles.subPlusElementV
												}
											/>
											<div
												className={
													styles.subPlusElementH
												}
											/>
										</div>
									)}
								</div>
								<div
									className={`${
										styles.mobileSubDropdownContainer
									} ${
										active === data.name ? styles.open : ""
									}`}
								>
									{data?.subnav?.map((item) => (
										<div
											key={item.name}
											className={`${styles.mobileSubDropdown}`}
										>
											<Link
												to={item.path}
												className={styles.mobileSubLink}
											>
												{item.name.replace(
													/<\/br>/g,
													" "
												)}
											</Link>
										</div>
									))}
								</div>
							</div>
						))} */}
					</div>
				</div>
				{/* END MOBILE */}
			</div>
		</nav>
	);
};

export default Navigation;
