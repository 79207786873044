import React from "react";
import PropTypes from "prop-types";
import * as styles from "./icon.module.scss";

// The list below is to be used when using a dropdown in prismic
// If you add an icon, please update this list as well

/*
  Beer
  Wine
  Price Tag
*/

/**
 * Returns an icon when passed that Icon's name.
 * Feel free to add additional props as needed!
 */
const Icon = ({ alt, icon, width, classList }) => {
	switch (icon) {
		case "Wine":
			return (
				<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style={{ enableBackground: "new 0 0 512 512" }}
					space="preserve"
					className={`${classList} ${styles.icon}`}
					// width={width}
					alt={alt}
				>
					<g>
						<g>
							<path
								d="M188.275,157.013V8.533c0-4.71-3.823-8.533-8.533-8.533h-51.2c-4.71,0-8.533,3.823-8.533,8.533v148.48
			c-31.727,17.954-51.209,51.2-51.2,87.979v258.475c0,4.719,3.823,8.533,8.533,8.533h153.6c4.71,0,8.533-3.814,8.533-8.533V245.001
			C239.483,208.213,220.002,174.967,188.275,157.013z M222.408,494.933H85.875V244.992c-0.017-32.06,17.801-60.885,46.481-75.221
			c2.893-1.451,4.719-4.403,4.719-7.637V17.067h34.133v145.067c0,3.234,1.826,6.195,4.719,7.637
			c28.681,14.327,46.49,43.153,46.481,75.221V494.933z"
							/>
						</g>
					</g>
					<g>
						<g>
							<rect
								x="128.516"
								y="42.667"
								width="51.2"
								height="17.067"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M442.397,276.207l-15.275-106.752c-0.597-4.198-4.198-7.322-8.448-7.322h-102.4c-4.241,0-7.842,3.123-8.456,7.322
			l-15.266,106.283c-1.451,9.728-0.922,19.482,1.502,28.817l1.485,6.255c8.277,33.041,37.854,56.115,71.919,56.124
			c0.051,0,0.085,0,0.128,0c33.843,0,63.369-22.869,71.851-55.808l1.399-5.956C443.319,295.646,443.839,285.884,442.397,276.207z
			 M424.281,301.056l-1.408,5.973c-6.502,25.233-29.227,42.837-55.287,42.837c-0.043,0-0.077,0-0.111,0
			c-26.232,0-49.007-17.766-55.347-43.093l-1.502-6.332c-1.903-7.279-2.295-14.746-1.178-22.229l14.217-99.012h87.612l14.242,99.473
			C426.628,286.114,426.235,293.581,424.281,301.056z"
							/>
						</g>
					</g>
					<g>
						<g>
							<rect
								x="358.916"
								y="358.4"
								width="17.067"
								height="110.933"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M384.541,460.8h-34.133c-23.526,0-42.667,19.14-42.667,42.667c0,4.719,3.814,8.533,8.533,8.533h102.4
			c4.719,0,8.533-3.814,8.533-8.533C427.208,479.94,408.068,460.8,384.541,460.8z M326.267,494.933
			c3.524-9.933,13.022-17.067,24.141-17.067h34.133c11.127,0,20.617,7.134,24.141,17.067H326.267z"
							/>
						</g>
					</g>
					<g>
						<g>
							<rect
								x="299.182"
								y="281.6"
								width="136.533"
								height="17.067"
							/>
						</g>
					</g>
					<g>
						<g>
							<rect
								x="128.516"
								y="119.467"
								width="51.2"
								height="17.067"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M196.808,264.533h-85.333c-4.71,0-8.533,3.814-8.533,8.533v153.6c0,4.719,3.823,8.533,8.533,8.533h85.333
			c4.71,0,8.533-3.814,8.533-8.533v-153.6C205.341,268.348,201.518,264.533,196.808,264.533z M188.275,418.133h-68.267V281.6h68.267
			V418.133z"
							/>
						</g>
					</g>
				</svg>
			);
		case "Price Tag":
			return (
				<svg
					x="0px"
					y="0px"
					viewBox="0 0 207.002 207.002"
					style={{ enableBackground: "new 0 0 512 512" }}
					space="preserve"
					className={`${classList} ${styles.icon}`}
				>
					<g>
						<path
							d="M199.502,0h-85.335c-1.989,0-3.897,0.79-5.304,2.197L2.197,108.865c-2.929,2.929-2.929,7.678,0,10.606l85.334,85.334
		c1.465,1.464,3.385,2.197,5.304,2.197c1.919,0,3.839-0.732,5.304-2.197L204.806,98.137c1.406-1.406,2.196-3.314,2.196-5.303V7.5
		C207.002,3.358,203.645,0,199.502,0z M192.002,89.728l-99.168,99.168l-74.728-74.728L117.274,15h74.729V89.728z"
						/>
						<path
							d="M156.835,72.752c6.032,0,11.704-2.349,15.971-6.615c8.805-8.806,8.804-23.134-0.002-31.94
		c-4.266-4.265-9.938-6.614-15.97-6.614s-11.704,2.349-15.97,6.615c-8.805,8.806-8.805,23.134,0,31.94
		C145.13,70.403,150.802,72.752,156.835,72.752z M151.472,44.803c1.432-1.432,3.337-2.221,5.362-2.221
		c2.026,0,3.932,0.789,5.363,2.221c2.958,2.958,2.958,7.77,0.002,10.727c-1.433,1.433-3.338,2.222-5.364,2.222
		s-3.932-0.789-5.363-2.221C148.515,52.573,148.515,47.761,151.472,44.803z"
						/>
					</g>
				</svg>
			);
		case "Beer":
			return (
				<svg
					x="0px"
					y="0px"
					viewBox="0 0 512.002 512.002"
					style={{ enableBackground: "new 0 0 512 512" }}
					space="preserve"
					className={`${classList} ${styles.icon}`}
				>
					<g>
						<g>
							<path
								d="M228.242,108.792l-48.176-12.909c-5.266-1.411-11.173-0.242-15.416,3.05c-3.227,2.502-5.236,6.047-5.673,9.917
			l-5.337,19.919c-1.573,3.644-1.586,7.801,0.006,11.644c0.524,1.265,1.209,2.463,2.025,3.573
			c-5.1,10.618-10.914,23.713-17.017,38.282c-0.007,0.017-0.014,0.033-0.021,0.049c-5.331,12.728-10.879,26.572-16.359,40.867
			c-18.682,5.068-42.505,26.934-49.436,52.794L26.98,447.128c-4.413,16.473,6.424,33.74,24.157,38.491l46.947,12.58
			c0,0,0.002,0,0.002,0.001l46.945,12.579c3.054,0.818,6.156,1.224,9.229,1.224c5.351,0,10.613-1.23,15.372-3.648
			c7.852-3.99,13.38-10.678,15.565-18.832l6.308-23.543c0.001-0.003,0.003-0.007,0.003-0.01c0.001-0.003,0.001-0.006,0.002-0.009
			l39.546-147.587c6.93-25.861-2.77-56.708-16.414-70.439c3.437-21.645,6.295-42.565,8.314-60.632
			c0.006-0.052,0.013-0.104,0.018-0.157c1.01-9.053,1.807-17.38,2.362-24.725c4.329-2.121,7.763-6.016,9.107-11.027l5.832-21.763
			C242.7,120.568,237.302,111.22,228.242,108.792z M165.878,484.344c-0.697,2.599-2.581,4.794-5.307,6.179
			c-3.085,1.567-6.766,1.9-10.365,0.936l-46.944-12.578c-0.001,0-0.001-0.001-0.002-0.001L56.313,466.3
			c-6.961-1.865-11.547-8.275-10.015-13.997l3.723-13.895l119.58,32.042L165.878,484.344z M174.778,451.132L55.197,419.09
			L89.57,290.813l119.58,32.042L174.778,451.132z M213.276,303.255l-117.482-31.48c8.671-17.117,26.267-29.503,34.042-29.699
			c4.061-0.102,7.658-2.652,9.099-6.451c5.197-13.704,10.479-27.05,15.591-39.444h47.256c-1.648,13.427-3.697,28.106-6.052,43.269
			l-2.445-0.655c-5.337-1.43-10.818,1.736-12.247,7.071c-1.429,5.335,1.736,10.818,7.071,12.247l11.479,3.076
			C206.103,266.396,214.278,284.986,213.276,303.255z M215.733,143.945c-0.079,0.001-0.157,0.01-0.236,0.013
			c-0.074,0.002-0.146,0.007-0.219,0.012c-0.243,0.015-0.484,0.038-0.723,0.07c-0.083,0.011-0.165,0.021-0.247,0.034
			c-0.239,0.038-0.475,0.086-0.71,0.141c-0.068,0.016-0.138,0.028-0.206,0.046c-0.287,0.074-0.57,0.159-0.848,0.258
			c-0.09,0.032-0.177,0.071-0.267,0.105c-0.187,0.073-0.373,0.148-0.555,0.231c-0.113,0.052-0.223,0.108-0.333,0.164
			c-0.157,0.079-0.311,0.161-0.464,0.248c-0.107,0.061-0.214,0.124-0.319,0.189c-0.159,0.098-0.313,0.203-0.466,0.31
			c-0.088,0.062-0.177,0.121-0.263,0.186c-0.203,0.152-0.399,0.312-0.59,0.479c-0.03,0.026-0.062,0.049-0.091,0.076
			c-0.225,0.202-0.439,0.415-0.646,0.636c-0.043,0.046-0.082,0.095-0.124,0.142c-0.157,0.175-0.309,0.355-0.454,0.542
			c-0.059,0.076-0.115,0.155-0.172,0.233c-0.119,0.162-0.233,0.328-0.342,0.498c-0.061,0.095-0.121,0.192-0.179,0.289
			c-0.094,0.158-0.182,0.321-0.268,0.485c-0.058,0.111-0.116,0.221-0.17,0.334c-0.075,0.16-0.143,0.324-0.21,0.488
			c-0.05,0.121-0.104,0.24-0.148,0.363c-0.088,0.242-0.165,0.49-0.235,0.742c-0.083,0.299-0.15,0.604-0.206,0.913
			c-0.012,0.065-0.03,0.13-0.041,0.195c-0.007,0.042-0.008,0.087-0.015,0.131c-0.044,0.302-0.079,0.604-0.096,0.905
			c-0.364,6.547-0.996,14.238-1.859,22.779h-41.044c5.17-11.881,9.983-22.289,14.13-30.493c0.131-0.26,0.248-0.53,0.357-0.804
			c0.019-0.049,0.045-0.097,0.064-0.146c0.02-0.054,0.034-0.111,0.054-0.165c0.11-0.304,0.206-0.61,0.286-0.919
			c0.065-0.253,0.123-0.507,0.167-0.76c0.022-0.124,0.034-0.248,0.051-0.372c0.025-0.184,0.05-0.367,0.064-0.55
			c0.01-0.117,0.014-0.234,0.019-0.351c0.009-0.196,0.014-0.391,0.011-0.585c-0.002-0.104-0.004-0.208-0.009-0.311
			c-0.009-0.216-0.027-0.43-0.05-0.643c-0.009-0.083-0.017-0.166-0.028-0.249c-0.034-0.255-0.079-0.508-0.133-0.759
			c-0.009-0.042-0.015-0.083-0.024-0.125c-0.069-0.302-0.151-0.6-0.247-0.893c-0.006-0.018-0.013-0.035-0.019-0.052
			c-0.087-0.26-0.186-0.517-0.294-0.768c-0.04-0.092-0.084-0.182-0.126-0.273c-0.081-0.175-0.165-0.348-0.257-0.518
			c-0.057-0.107-0.117-0.212-0.178-0.317c-0.088-0.151-0.181-0.299-0.277-0.446c-0.069-0.106-0.138-0.212-0.211-0.316
			c-0.11-0.155-0.228-0.304-0.347-0.453c-0.066-0.083-0.128-0.169-0.198-0.25c-0.188-0.22-0.387-0.432-0.594-0.635
			c-0.063-0.062-0.13-0.118-0.195-0.178c-0.162-0.151-0.328-0.298-0.501-0.439c-0.074-0.06-0.151-0.118-0.227-0.176
			c-0.181-0.139-0.367-0.271-0.559-0.398c-0.069-0.045-0.137-0.091-0.208-0.135c-0.063-0.04-0.124-0.085-0.188-0.123l4.454-16.606
			l42.409,11.363L215.733,143.945z"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M164.797,231.165l-0.234-0.062c-5.335-1.422-10.816,1.749-12.239,7.085c-1.424,5.337,1.748,10.816,7.085,12.239
			l0.234,0.063c0.863,0.23,1.73,0.34,2.583,0.34c4.419,0,8.463-2.953,9.656-7.426C173.305,238.067,170.133,232.588,164.797,231.165z
			"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M164.826,352.121c-5.036-8.723-13.167-14.961-22.896-17.568c-3.198-0.857-6.487-1.292-9.775-1.292
			c-17.024,0-31.993,11.495-36.401,27.953c-2.607,9.728-1.27,19.889,3.766,28.611c5.036,8.722,13.167,14.961,22.895,17.567
			c3.198,0.857,6.487,1.291,9.775,1.291c17.023,0,31.993-11.494,36.402-27.953C171.2,371.003,169.862,360.842,164.826,352.121z
			 M149.275,375.555c-2.071,7.73-9.096,13.128-17.084,13.128c-1.541,0-3.088-0.205-4.6-0.61c-4.568-1.224-8.386-4.154-10.75-8.249
			c-2.365-4.096-2.993-8.866-1.768-13.435c2.07-7.73,9.095-13.129,17.083-13.129c1.541,0,3.088,0.205,4.6,0.61
			c4.568,1.224,8.386,4.154,10.751,8.25C149.87,366.216,150.498,370.987,149.275,375.555z"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M485.012,447.127l-45.859-171.149c-6.93-25.862-30.753-47.728-49.435-52.794c-5.48-14.295-11.028-28.14-16.359-40.867
			c-0.007-0.017-0.014-0.033-0.021-0.05c-6.103-14.569-11.917-27.664-17.018-38.281c0.815-1.109,1.5-2.308,2.024-3.572
			c1.593-3.843,1.579-8.001,0.007-11.645l-5.338-19.919c-0.437-3.869-2.445-7.414-5.672-9.916c-4.243-3.293-10.15-4.462-15.417-3.05
			l-48.177,12.908c-4.382,1.175-8.048,3.992-10.322,7.932c-2.275,3.941-2.882,8.525-1.707,12.907l5.83,21.764
			c1.344,5.011,4.778,8.906,9.108,11.026c0.554,7.341,1.35,15.663,2.359,24.711c0.006,0.064,0.015,0.128,0.022,0.192
			c2.018,18.061,4.875,38.972,8.311,60.61c-13.644,13.73-23.343,44.579-16.414,70.439l45.859,171.148
			c2.186,8.155,7.714,14.843,15.566,18.832c4.759,2.419,10.021,3.648,15.372,3.648c3.072,0,6.175-0.405,9.228-1.224l93.895-25.159
			c8.372-2.243,15.508-7.357,20.093-14.399C485.754,463.837,487.197,455.282,485.012,447.127z M297.769,144.084
			c-0.149-0.024-0.3-0.044-0.451-0.062c-0.172-0.021-0.344-0.037-0.518-0.049c-0.118-0.008-0.236-0.016-0.356-0.02
			c-0.063-0.002-0.124-0.009-0.186-0.01l-4.449-16.608l42.409-11.36l4.451,16.606c-0.064,0.039-0.125,0.083-0.188,0.123
			c-0.071,0.045-0.141,0.091-0.211,0.137c-0.19,0.126-0.375,0.258-0.555,0.395c-0.077,0.06-0.155,0.118-0.231,0.179
			c-0.17,0.139-0.334,0.284-0.494,0.433c-0.066,0.062-0.136,0.121-0.201,0.184c-0.205,0.202-0.403,0.413-0.59,0.631
			c-0.073,0.085-0.139,0.176-0.209,0.264c-0.115,0.144-0.229,0.289-0.336,0.438c-0.076,0.106-0.146,0.215-0.218,0.325
			c-0.094,0.143-0.185,0.287-0.271,0.435c-0.063,0.107-0.124,0.216-0.183,0.326c-0.09,0.167-0.172,0.336-0.252,0.507
			c-0.044,0.094-0.09,0.187-0.13,0.282c-0.105,0.246-0.201,0.497-0.287,0.751c-0.008,0.023-0.018,0.046-0.025,0.069
			c-0.096,0.292-0.177,0.589-0.246,0.889c-0.01,0.046-0.017,0.092-0.026,0.138c-0.053,0.246-0.098,0.495-0.132,0.746
			c-0.011,0.085-0.018,0.171-0.028,0.256c-0.023,0.211-0.04,0.423-0.05,0.637c-0.005,0.104-0.008,0.209-0.01,0.314
			c-0.002,0.193,0.003,0.386,0.012,0.581c0.005,0.119,0.01,0.236,0.018,0.355c0.015,0.183,0.04,0.365,0.065,0.548
			c0.017,0.124,0.029,0.249,0.052,0.372c0.047,0.266,0.105,0.531,0.175,0.796c0.072,0.278,0.162,0.554,0.26,0.828
			c0.026,0.073,0.046,0.15,0.073,0.223c0.012,0.031,0.028,0.061,0.04,0.091c0.116,0.296,0.243,0.585,0.385,0.864
			c4.147,8.203,8.958,18.607,14.127,30.485h-41.043c-0.863-8.542-1.496-16.233-1.859-22.779c-0.017-0.303-0.052-0.608-0.097-0.913
			c-0.006-0.041-0.008-0.082-0.014-0.122c-0.011-0.066-0.029-0.132-0.042-0.198c-0.053-0.294-0.114-0.586-0.192-0.871
			c-0.073-0.268-0.156-0.53-0.25-0.787c-0.041-0.114-0.091-0.224-0.137-0.337c-0.071-0.175-0.144-0.35-0.224-0.519
			c-0.05-0.105-0.105-0.208-0.159-0.312c-0.09-0.173-0.183-0.344-0.281-0.511c-0.055-0.092-0.111-0.182-0.168-0.272
			c-0.112-0.175-0.23-0.346-0.353-0.513c-0.056-0.076-0.109-0.153-0.167-0.227c-0.142-0.183-0.291-0.359-0.444-0.531
			c-0.048-0.053-0.092-0.108-0.14-0.16c-0.201-0.215-0.41-0.422-0.628-0.618c-0.075-0.068-0.156-0.129-0.233-0.195
			c-0.142-0.12-0.283-0.239-0.432-0.35c-0.124-0.095-0.254-0.182-0.383-0.271c-0.105-0.071-0.209-0.142-0.316-0.209
			c-0.15-0.095-0.304-0.186-0.46-0.273c-0.086-0.048-0.173-0.093-0.26-0.139c-0.175-0.091-0.351-0.18-0.531-0.261
			c-0.055-0.024-0.11-0.045-0.165-0.069c-0.605-0.258-1.238-0.456-1.895-0.59C298.011,144.131,297.891,144.105,297.769,144.084z
			 M312.407,261.189l11.478-3.075c5.335-1.429,8.501-6.913,7.071-12.247c-1.43-5.334-6.915-8.502-12.247-7.071l-2.445,0.655
			c-2.354-15.164-4.403-29.844-6.052-43.269h47.257c5.112,12.394,10.395,25.74,15.591,39.444c1.441,3.798,5.037,6.348,9.099,6.45
			c7.774,0.196,25.369,12.582,34.04,29.7l-117.482,31.48C297.715,284.985,305.891,266.395,312.407,261.189z M337.214,451.133
			l-34.372-128.278l119.58-32.042l34.372,128.278L337.214,451.133z M464.188,460.307c-1.889,2.9-4.91,5.029-8.508,5.993
			l-93.895,25.159c-3.599,0.964-7.278,0.632-10.364-0.936c-2.726-1.385-4.61-3.579-5.307-6.179l-3.723-13.894l119.58-32.042
			l3.723,13.895C466.39,454.903,465.856,457.746,464.188,460.307z"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M359.665,238.178c-1.429-5.334-6.909-8.503-12.246-7.073l-0.233,0.063c-5.335,1.429-8.501,6.912-7.072,12.246
			c1.196,4.469,5.236,7.416,9.653,7.416c0.855,0,1.727-0.111,2.593-0.343l0.233-0.063
			C357.928,248.995,361.094,243.512,359.665,238.178z"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M416.238,361.214c-4.409-16.459-19.379-27.954-36.402-27.954c-3.288,0-6.577,0.435-9.775,1.292
			c-9.729,2.606-17.859,8.845-22.896,17.568c-5.035,8.722-6.373,18.883-3.767,28.612c4.41,16.456,19.38,27.951,36.402,27.952
			c0.001,0,0.001,0,0.002,0c3.287,0,6.576-0.435,9.774-1.291c9.728-2.606,17.859-8.845,22.895-17.567
			C417.507,381.103,418.845,370.942,416.238,361.214z M395.152,379.825c-2.365,4.096-6.183,7.025-10.75,8.249
			c-1.511,0.405-3.059,0.61-4.599,0.61c0,0,0,0-0.001,0c-7.987,0-15.014-5.399-17.085-13.128c-1.224-4.568-0.596-9.339,1.768-13.435
			c2.365-4.096,6.183-7.025,10.751-8.25c1.511-0.405,3.059-0.61,4.6-0.61c7.987,0,15.013,5.399,17.084,13.13
			C398.144,370.958,397.516,375.729,395.152,379.825z"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M255.996,0c-5.523,0-10,4.477-10,10v26.764c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10V10
			C265.996,4.477,261.519,0,255.996,0z"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M222.552,50.193l-13.596-23.178c-2.794-4.764-8.922-6.361-13.685-3.566c-4.764,2.794-6.36,8.921-3.566,13.685
			l13.596,23.178c1.862,3.174,5.203,4.942,8.635,4.942c1.719,0,3.461-0.443,5.05-1.376C223.75,61.083,225.346,54.956,222.552,50.193
			z"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M316.723,23.449c-4.765-2.795-10.892-1.197-13.686,3.566l-13.596,23.178c-2.794,4.764-1.198,10.891,3.566,13.685
			c1.59,0.933,3.331,1.376,5.05,1.376c3.432,0,6.773-1.768,8.636-4.942l13.596-23.178C323.083,32.37,321.486,26.243,316.723,23.449z
			"
							/>
						</g>
					</g>
				</svg>
			);
		default:
			return null;
	}
};

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	width: PropTypes.string,
	classList: PropTypes.string,
};

Icon.defaultProps = {
	width: "70",
	classList: "",
};

export default Icon;
