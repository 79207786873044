import React from "react";
import PropTypes from "prop-types";

import FeaturedProducts from "./featuredProducts/featuredProducts";
import CallToAction from "./callToAction/callToAction";
import generalContentWithHeadline from "./generalContentWithHeadline/generalContentWithHeadline";
import heroImage from "./heroImage/heroImage";
import alertBanner from "./alertBanner/alertBanner";


const SliceZone = ({ sliceZone }) => {
	const sliceComponents = {
		hero_image_and_title: heroImage,
		alert_banner: alertBanner,
		featured_product_section: FeaturedProducts,
		call_to_action: CallToAction,
		general_content_with_headline: generalContentWithHeadline,
	};

	const sliceZoneContent = sliceZone.map((slice) => {
		const SliceComponent = sliceComponents[slice.slice_type];

		if (SliceComponent) {
			return <SliceComponent slice={slice} key={`slice-${slice.id}`} />;
		}
		return null;
	});

	return <section>{sliceZoneContent}</section>;
};

export default SliceZone;

SliceZone.propTypes = {
	sliceZone: PropTypes.object.isRequired,
};
