import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { PrismicLink, PrismicText, PrismicRichText } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import * as styles from "./heroImage.module.scss";

const HeroImage = ({ slice }) => {
	if (!slice) return null;
	const doc = slice;

	console.log("HERO IMAGE = > ", doc);

	const heroImage = getImage(doc?.primary.homepage_hero_background_image);

	return (
		<div className="w-100">
			<div
				style={{
					backgroundImage: `url(${doc?.primary.hero_background_image.url})`,
				}}
				className={`headline-font ${styles.heroImage}`}
			>
				<div className={styles.heroImageText}>
					<PrismicRichText
						components={{
							paragraph: ({ children }) => (
								<p className={`text-center ${styles.text}`}>
									{children}
								</p>
							),
							heading2: ({ children }) => (
								<h2 className={`text-center ${styles.text}`}>
									{children}
								</h2>
							),
							heading4: ({ children }) => (
								<h4 className={`text-center ${styles.text}`}>
									{children}
								</h4>
							),
						}}
						field={doc?.primary.hero_text.richText}
					/>
				</div>
			</div>
		</div>
	);
};

export default withPrismicPreview(HeroImage);

HeroImage.propTypes = {
	data: PropTypes.object.isRequired,
};
